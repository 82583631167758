import React, { FC, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
// @ts-ignore
import DanceVideo from '../videos/dancevideo.mp4';
import { Routes } from '../constants/Routes';
import Image from 'gatsby-image';
import useIsDesktop from '../hooks/useIsDesktop';

const IMAGE_QUERY = graphql`
  query {
    team: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const HomePage: FC = () => {
  const isDesktop = useIsDesktop();
  const data = useStaticQuery(IMAGE_QUERY);
  const videoRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    // lazy load videos in all browsers
    if (videoRef && videoRef.current && isDesktop) {
      videoRef.current.src =
        'https://res.cloudinary.com/bassplayerch/video/upload/f_auto,q_auto/v1585808004/Tanzstudio%20Aha/Mobile_Device_720P_25_AHA_15122019.mp4';
      videoRef.current.load();
    }
  }, [videoRef, isDesktop]);

  return (
    <Layout location={Routes.HOME}>
      <SEO title="Home" />
      {isDesktop ? (
        <div className="overflow-hidden h-full object-cover">
          <video ref={videoRef} className="w-full object-cover h-full" loop muted autoPlay controls playsInline>
            <source data-src={DanceVideo} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div className="h-full">
          <Image className="h-full object-cover" fluid={data.team.childImageSharp.fluid} />
        </div>
      )}

    </Layout>
  );
};

export default HomePage;
